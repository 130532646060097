<template>
  <div class="container-fluid filter-content">
    <div class="row no-gutters p-0 px-1 mb-2 justify-content-between">
      <div class="col-12 d-flex">
        <h5 class="mb-md-1">Orders</h5>
      </div>
    </div>

    <div>
      <div class="row mb-2 p-1">
        <div class="col-12 col-md-4 mb-2 mb-md-0 p-0">
          <div class="h-100 pr-0 pr-md-2">
            <div class="order-card">
              <div class="d-flex justify-content-between align-items-center">
                <div class="order-card-icon">
                  <img src="../../assets/images/icon/bag.png">
                </div>
                <!-- Dropdown -->
                <!-- <div class="dropdown">
                  <button class="btn  dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    {{ selectedFilter }}
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <a class="dropdown-item" href="#" @click="selectFilter('Today')">Today</a>
                    <a class="dropdown-item" href="#" @click="selectFilter('Yesterday')">Yesterday</a>
                    <a class="dropdown-item" href="#" @click="selectFilter('This Week')">This Week</a>
                    <a class="dropdown-item" href="#" @click="selectFilter('Last Week')">Last Week</a>
                    <a class="dropdown-item" href="#" @click="selectFilter('This Year')">This Year</a>
                    <a class="dropdown-item" href="#" @click="selectFilter('Last Year')">Last Year</a>
                  </div>
                </div>
                 -->
              
                </div>


                <div class="mt-3" v-if="showLoader == 1">
                  <content-placeholders :rounded="true" >
                    <content-placeholders-heading :img="false" />
                  </content-placeholders>
                </div>

                <div v-else class="order-card-content mt-2">
                  <div class="">
                    <span>Orders</span>
                    <p v-if="totalStatus.totalItems">{{ totalStatus.totalItems }}</p>
                    <p v-else>0</p>
                  </div>

                  <!-- <div class="col">
                    <span>Pending</span>
                    <p>5</p>
                  </div> -->
                  
                  <div class="">
                    <span>Pending</span>
                    <p v-if="totalStatus.pending">{{ totalStatus.pending }}</p>
                    <p v-else>0</p>

                  </div>
                </div>

            </div>

          </div>
        </div>

        <!-- Repeated card with the same dropdown -->
        <div class="col-12 col-md-4 mb-2 mb-md-0 p-0">
          <div class="h-100 pr-0 pr-md-2">
            <div class="order-card">
              <div class="d-flex justify-content-between align-items-center">
                <div class="order-card-icon">
                  <img src="../../assets/images/icon/bag.png">
                </div>
                <!-- <div class="dropdown">
                  <button class="btn  dropdown-toggle" type="button" id="dropdownMenuButton2" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    {{ selectedFilter1 }}
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                    <a class="dropdown-item" href="#" @click="selectFilter1('Today')">Today</a>
                    <a class="dropdown-item" href="#" @click="selectFilter1('Yesterday')">Yesterday</a>
                    <a class="dropdown-item" href="#" @click="selectFilter1('This Week')">This Week</a>
                    <a class="dropdown-item" href="#" @click="selectFilter1('Last Week')">Last Week</a>
                    <a class="dropdown-item" href="#" @click="selectFilter1('This Year')">This Year</a>
                    <a class="dropdown-item" href="#" @click="selectFilter1('Last Year')">Last Year</a>
                  </div>
                </div> -->

              </div>

               <div class="mt-3" v-if="showLoader == 1">
                  <content-placeholders :rounded="true" >
                    <content-placeholders-heading :img="false" />
                  </content-placeholders>
                </div>

              <div v-else class="order-card-content mt-2">
                <div class="">
                  <span>Approved</span>
                    <p v-if="totalStatus.approved">{{ totalStatus.approved }}</p>
                    <p v-else>0</p>
                </div
                >
                <!-- <div class="col">
                  <span>Returned</span>
                  <p>20</p>
                </div> -->
                
                <div class="">
                  <span>Disaaproved</span>
                    <p v-if="totalStatus.disapproved">{{ totalStatus.disapproved }}</p>
                    <p v-else>0</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-4 mb-2 mb-md-0 p-0">
          <div class="h-100 pr-0 pr-md-2">
            <div class="order-card">
              <div class="d-flex justify-content-between align-items-center">
                <div class="order-card-icon">
                  <img src="../../assets/images/icon/refund.png">
                </div>
                <!-- <div class="dropdown">
                  <button class="btn  dropdown-toggle" type="button" id="dropdownMenuButton3" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    {{ selectedFilter2 }}
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                    <a class="dropdown-item" href="#" @click="selectFilter2('Today')">Today</a>
                    <a class="dropdown-item" href="#" @click="selectFilter2('Yesterday')">Yesterday</a>
                    <a class="dropdown-item" href="#" @click="selectFilter2('This Week')">This Week</a>
                    <a class="dropdown-item" href="#" @click="selectFilter2('Last Week')">Last Week</a>
                    <a class="dropdown-item" href="#" @click="selectFilter2('This Year')">This Year</a>
                    <a class="dropdown-item" href="#" @click="selectFilter2('Last Year')">Last Year</a>
                  </div>
                </div> -->

              </div>

               <div class="mt-3" v-if="showLoader == 1">
                  <content-placeholders :rounded="true" >
                    <content-placeholders-heading :img="false" />
                  </content-placeholders>
                </div>

              <div v-else class="order-card-content mt-2">
                <div class="">
                  <span>Refunded</span>
                    <p v-if="totalStatus.fully_refunded">{{ totalStatus.fully_refunded }}</p>
                    <p v-else>0</p>
                </div>
                <div class="">
                  <span>Partial Refunded</span>
                    <p v-if="totalStatus.partially_refunded">{{ totalStatus.partially_refunded }}</p>
                    <p v-else>0</p>
                </div>
              </div>
              

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "OrderCards",
  data() {
    return {
      visible: true,
      selectedFilter: "This Week", // Default filter
      selectedFilter1: "This Week", // Default filter
      selectedFilter2: "This Week", // Default filter
    };
  },
  props: {
    totalStatus: {
      type: Object,
      default: () => ({}),
    },
    showLoader: {
      type: Number, // Use Number to accept 0 or 1
      default: 1,   // Default value is 0
    }

  },

  methods: {
    selectFilter(filter) {
      this.selectedFilter = filter; // Update the filter name when an option is selected
    },
    selectFilter1(filter) {
      this.selectedFilter1 = filter; // Update the filter name when an option is selected
    },
    selectFilter2(filter) {
      this.selectedFilter2 = filter; // Update the filter name when an option is selected
    },
  },
  mounted() {
    this.$emit("showChange", this.visible);
  },
};
  

</script>


<style scoped>
.order-card {
    border-radius: 15px;
    padding: 10px;
    background: var(--registry-wrapper);
    color: var(--text-primary-color);
    height: 100%;
    /* margin-bottom: 10px; */
}
.order-card-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between
  }
.order-card-content span{
  color: #8B8D97;
  font-size: 14px;
  text-align: center;
  }
.order-card-content p{
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  }
.dropdown-toggle {
  color: #8B8D97;
  }
.dropdown-toggle:focus {
  box-shadow: none!important;
  }
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--light-sky)!important;
  }
</style>
