import { render, staticRenderFns } from "./OrderTop.vue?vue&type=template&id=1b936aa5&scoped=true&"
import script from "./OrderTop.vue?vue&type=script&lang=js&"
export * from "./OrderTop.vue?vue&type=script&lang=js&"
import style0 from "./OrderTop.vue?vue&type=style&index=0&id=1b936aa5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b936aa5",
  null
  
)

export default component.exports