<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper p-0 bg-transparent overflow-hidden">
        <div class="">
          <!-- <OrderFilters  @clearFilter="handleClearFilter"  @showChange="gettingValueChanged" /> -->
          <OrderCards
            @showChange="gettingValueChanged"
            :totalStatus="totalStatus"
            :showLoader="showLoader" />
            
        </div>
        <div class="home-registry-wrapper">
          <OrderTop  :count="count" :searched="filter.search" @clearFilter="handleClearFilter" />
          <div
            v-if="showLoader == 1"
            class="lds-dual-ring-registry"
            id="loading"
          ></div>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade"
              id="registry-table"
              v-bind:class="{ active: tabindex == 0, show: tabindex == 0 }"
              role="tabpanel"
              aria-labelledby="registry-table-tab"
            >
              
              <OrderTable :message="parentMessage" :orderList="orderList" :showLoader="showLoader" :isFilterApplied="isFilterApplied"/>
            </div>
          </div>
          <OrderBottom  :page="pager" :count="count" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import OrderTop from "../components/orders/OrderTop.vue";
import OrderTable from "../components/orders/OrderTable.vue";
import OrderBottom from "../components/orders/OrderBottom.vue";
import Navbar from "../components/header/Navbar.vue";
// import OrderFilters from "../components/orders/OrderFilter.vue";
import OrderCards from "../components/orders/OrderCards.vue";

export default {
  name: "Home",

  components: {
    Navbar,
    LeftMenu,
    OrderTop,
    OrderTable,
    OrderBottom,
    // OrderFilters,
    OrderCards,
  },

  data: function() {
    return {
      parentMessage: false,
      errorMessage: null,
      orderList: [],
      showLoader: 0,
      page: 1, // Ensure page starts at 1
      pager: {},
      perPage: 3,
      pages: [],
      success: null,
      error: null,
      count: 0,
      tabindex: 0,
      filter: {},
      pageSize: "15",
      totalStatus: {},
      isFilterApplied: false, // New state to check if filters are applied

    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions("auth", ["sendVerifyResendRequest"]),

    gettingValueChanged(value) {
      this.parentMessage = value;
    },

    handleClearFilter(clearedFilter) {
      console.log("hitt")
      this.filter = clearedFilter; // Update filter with cleared filter object

      const isFilterApplied = Object.keys(this.filter).some(key => {
        const value = this.filter[key];
        // Check if value is a string and non-empty after trimming, or if value is not empty
        return (typeof value === 'string' && value.trim() !== "") || (typeof value === 'number' && value !== null && value !== "");
      });

      if (isFilterApplied) {
        // If any filter is applied, reset to page 1
        this.page = 1;
        this.isFilterApplied = true;

      } else {
        // If no filter is applied (filters are cleared), fetch page from the URL
        const urlParams = new URLSearchParams(window.location.search);
        this.isFilterApplied = false;
        const page = urlParams.get('page') || 1; // Default to page 1 if no page parameter
        this.page = parseInt(page, 10);

        // Log the page number to the console
        console.log("Page from URL:", this.page);
      }



      this.getDataRegistry(); // Fetch data based on cleared filters
    },

    getDataRegistry() {
      this.showLoader = 1;
      const user_id = this.user && this.user.id ? this.user.id : localStorage.getItem('loginUserId');

      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAllOrders?page=${this.page}&size=${
            this.pageSize ? this.pageSize : ""}&customer_name=${
            this.filter.customer_name ? this.filter.customer_name : ""
          }&id=${
            this.filter.order_id ? this.filter.order_id : ""
          }&shopName=${
            this.filter.shopName ? this.filter.shopName : ""
          }&Risk_level=${
            this.filter.risk_level ? this.filter.risk_level : ""
          }&search=${
            this.filter.search ? this.filter.search : ""
          }&email=${
            this.filter.email ? this.filter.email : ""
          }&phone=${
            this.filter.phone ? this.filter.phone : ""
          }&UserID=${user_id }`,
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.orderList = response.data.data.listing;
            this.pager = response.data.data;
            this.count = response.data.data.totalItems;
            this.totalStatus = response.data.data.totalStatus;
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        })
        .catch((error) => {
          console.error(error);
          this.showLoader = 0;
        });
    },
  },

  watch: {
    "$route.query.page": {          
      immediate: true,
      handler(newPage) {
        newPage = parseInt(newPage) || 1;
        if (newPage !== this.page) {
          this.page = newPage;
          this.showLoader = 1;
          const user_id = this.user && this.user.id ? this.user.id : localStorage.getItem('loginUserId');
          axios
            .get(
              `${process.env.VUE_APP_API_URL}getAllOrders?page=${this.page}&size=${
                this.pageSize ? this.pageSize : ""}&customer_name=${
                this.filter.customer_name ? this.filter.customer_name : ""
              }&id=${
                this.filter.order_id ? this.filter.order_id : ""
              }&shopName=${
                this.filter.shopName ? this.filter.shopName : ""
              }&Risk_level=${
                this.filter.risk_level ? this.filter.risk_level : ""
              }&search=${
                this.filter.search ? this.filter.search : ""
              }&email=${
                this.filter.email ? this.filter.email : ""
              }&phone=${
                this.filter.phone ? this.filter.phone : ""
              }&UserID=${
                user_id
              }`,
              {}
            )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.orderList = response.data.data.listing;
                this.pager = response.data.data;
                this.count = response.data.data.totalItems;
                this.totalStatus = response.data.data.totalStatus;
                this.showLoader = 0;
              } else {
                this.errorMessage = response.data.message;
                this.showLoader = 0;
              }
            });
        }
      },
    },
  },

  mounted() {
    this.getDataRegistry();

    this.$root.$on("filterOrders", (data) => {
      this.filter = data;
      this.page = 1; // Reset page to 1 when filter is applied
      this.getDataRegistry();
    });
    
    this.$root.$on("searchForOrders", (data) => {
      this.filter = data;
        console.log("q", this.filter);

      // Check if search is empty or contains only whitespace
      if (this.filter && this.filter.search && this.filter.search.trim() !== "") {
        this.page = 1;
        this.isFilterApplied = true;

      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page') || 1;  // Default to page 1 if no page parameter
        this.page = parseInt(page, 10);
        this.isFilterApplied = false;
        console.log("Page from URL:", this.page);
      }
      this.getDataRegistry();
    });

    this.$root.$on("viewHandle", (data) => {
      this.tabindex = data;
    });

    this.$root.$on("update-data", () => {
      this.getDataRegistry();
    });
  },
  
  created() {},
};
</script>

