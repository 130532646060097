<template>
  <div>
    <CustomRangeModal />

    <div class="registry-wrapper-top mb-2">

      <div class="heading">
        <h5>Orders</h5>
      </div>
      <div class="buttons">

        <div class="form-group mb-0 mx-2">
          <button class="search-icon" @click="search">
            <img src="../../assets/images/search.png" alt="Search" />
          </button>
          <input class="form-control" type="search" placeholder="Search Here..." aria-label="Search"
            v-model="filter.search" @keydown.enter="search"
            title="You can search by Order Number, Customer & by Email." />
        </div>

        <div class="form-filter mb-0 mr-2 dropdown" @click="toggleDropdown">
          <img src="../../assets/images/shock-sjield/filter.png" class="mr-2" >
          <span>Filter</span>

          <!-- Dropdown Menu -->
          <div class="dropdown-menu dropdown-menu-right px-3" :class="{ show: isDropdownOpen }" @click.stop>
            <!-- Close Icon -->
            <div class="d-flex justify-content-between align-items-center px-0">
              <span class="bold">Filter</span>
              <i class="fa fa-times" type="button" aria-label="Close" @click="toggleDropdown"></i>

            </div>

            <div class="px-2 py-1">
              <div class="row flex-column">
                <div class="">
                  <div class="form-group">
                    <div class="date-pick">
                      <input class="h-100" type="text" placeholder="Customer Name" v-model="filter.customer_name"
                        @keyup.enter="applyFilter">
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="form-group">
                    <div class="date-pick">
                      <input class="h-100" type="number" placeholder="Order Id" v-model.number="filter.order_id"
                        @keyup.enter="applyFilter">
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="form-group">
                    <div class="date-pick">
                      <input class="h-100" placeholder="Email" v-model="filter.email" @keyup.enter="applyFilter">
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <button class="custom-btn filter-btn m-0" @click="applyFilter">
                    Apply Filter
                  </button>
                  <button class="custom-btn filter-btn m-0 ml-4" @click="clearFilter()">
                    Reset Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-filter dropdown d-none">
          <img src="../../assets/images/shock-sjield/Calendar.png" @click="toggleDateFilter" class="mr-2">
          <span @click="toggleDateFilter">Calendar</span>

          <!-- Dropdown Menu for Calendar -->
          <div class="dropdown-menu dropdown-menu-right px-2" :class="{ show: dateFilterOpen }">
            <div class="dropdown-content">
              <!-- Close Icon -->
              <div class="d-flex justify-content-between align-items-center px-0">
                <span class="bold">By Date</span>
                <i class="fa fa-times" type="button" aria-label="Close" @click="closeDateFilter"></i>
              </div>

             

              <!-- Date Options -->
              <a href="#today" @click="getFilters('today')">
                <img src="../../assets/images/calender.png" alt="" />
                <div class="date-content">
                  <div class="range">Today</div>
                  <div class="date">{{ new Date() | moment('MMM D') }}</div>
                </div>
              </a>
              <div class="line-seperator my-0"></div>
              <a href="#yesterday" @click="getFilters('yesterday')">
                <img src="../../assets/images/calender.png" alt="" />
                <div class="date-content">
                  <div class="range">Yesterday</div>
                  <div class="date">{{ dateYesterday | moment('MMM D') }}</div>
                </div>
              </a>
              <div class="line-seperator my-0"></div>
              <a href="#lastweek" @click="getFilters('lastweek')">
                <img src="../../assets/images/calender.png" alt="" />
                <div class="date-content">
                  <div class="range">Last Week</div>
                  <div class="date">{{ dateEndWeek | moment('MMM D') }} - {{ dateStartWeek | moment('D') }}</div>
                </div>
              </a>
              <div class="line-seperator my-0"></div>
              <a href="#lastmonth" @click="getFilters('lastmonth')">
                <img src="../../assets/images/calender.png" alt="" />
                <div class="date-content">
                  <div class="range">Last Month</div>
                  <div class="date">{{ dateEndMonth | moment('MMM D') }} - {{ dateStartMonth | moment('MMM D') }}</div>
                </div>
              </a>
              <div class="line-seperator my-0"></div>
              <a href="#customRange" @click="callRangeModal()">
                <img src="../../assets/images/calender.png" alt="" />
                <div class="date-content">
                  <div class="range">Custom Range</div>
                </div>
              </a>
            </div>
          </div>
        </div>



      </div>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import CustomRangeModal from "./CustomrRangeModal.vue";

export default {
  name: "OrderTop",
  props: {
    count: Number,
    searched: String,
  },
  data() {
    return {
      dateFilter: false,
      rowsFilter: false,
      filterText: "Select",
      dateToday: "",
      dateYesterday: "",
      dateStartWeek: "",
      dateEndWeek: "",
      dateStartMonth: "",
      dateEndMonth: "",
      filter: {
        search: "",
        customer_name: '',
        order_id: null,
        phone: '',
        email: '',
        shopName: '',
        risk_level: ''
      },
      dateFilterOpen: false,  // For controlling calendar dropdown visibility
      isDropdownOpen: false,
    
    };
  },
  components: {
    CustomRangeModal,
  },
  watch: {
    'filter.search'(newVal) {
      if (newVal === "") {
        this.refreshListing(); // Refresh listing when the search input is cleared
      }
    },
  },
  methods: {
    toggleDateFilter() {
      if (this.isDropdownOpen) {
        this.isDropdownOpen = false; // Close the dropdown if it's open
      }
      this.dateFilterOpen = !this.dateFilterOpen; // Toggle the date filter
    },
    closeDateFilter() {
      this.dateFilterOpen = false;
    },
    toggleDropdown() {
      console.log("jitted")
      this.isDropdownOpen = !this.isDropdownOpen; // Toggle the dropdown
    },
    
    clearFilter() {
      // Reset filter values
      this.filter = {
        customer_name: '',
        order_id: null,
        phone: '',
        email: '',
        shopName: '',
        risk_level: ''
      };
      // Emit the cleared filter to the parent component
        this.$emit("clearFilter", { ...this.filter });
    },
   

    applyFilter() {
      // Explicitly assign input values to the filter object
      this.filter.customer_name = this.filter.customer_name || null;
      this.filter.order_id = this.filter.order_id || '';
      this.filter.phone = this.filter.phone || '';
      this.filter.email = this.filter.email || '';
      this.filter.shopName = this.filter.shopName || '';
      this.filter.risk_level = this.filter.risk_level || '';
      
      // Check if any filter field is filled before emitting
      if (
        this.filter.customer_name !== null ||
        this.filter.order_id !== '' ||
        this.filter.phone !== '' ||
        this.filter.email !== '' ||
        this.filter.risk_level !== '' ||
        this.filter.shopName !== ''
      ) {
        // Emit the updated filter object to the parent component
        this.$emit("clearFilter", { ...this.filter });
      } else {
        console.log("No filter applied.");
        this.$emit("clearFilter", { ...this.filter });

      }
    },
    search() {
      console.log(this.filter);
      this.$root.$emit("searchForOrders", this.filter);
    },
    refreshListing() {
      this.$root.$emit("searchForOrders", { search: "" }); // Emit an event to fetch all orders
    },
    orderFilter(){
      const elem = this.$refs.orderFilter;
      if (elem) {
        elem.click();
      }
    },

    openDateFilter: function () {
      this.dateFilter = !this.dateFilter;
    },
    dateFilterOutside: function () {
      this.dateFilter = false;
    },

    openRowsFilter: function () {
      this.rowsFilter = !this.rowsFilter;
    },

    rowsFilterOutside: function () {
      this.rowsFilter = false;
    },

    filterFunction() {
      var input, filter, a, i, div, txtValue;
      filter = input.value.toUpperCase();
      div = document.getElementById("myDropdown");
      a = div.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    },
    getFilters(value) {
      if (value === "today") {
        this.filterText = "Today";
        const today = new Date();
        this.filter.startDate = moment(today, "YYYY/MM/DD").format("YYYY-MM-DD");
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      } else if (value === "yesterday") {
        this.filterText = "Yesterday";
        const today = new Date();
        today.setDate(today.getDate() - 1);
        this.filter.startDate = moment(today, "YYYY/MM/DD").format("YYYY-MM-DD");
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      } else if (value === "lastweek") {
        this.filterText = "Last Week";
        var week = new Array();
        let current = moment().subtract(1, "days");
        for (var i = 0; i < 7; i++) {
          week.push(current.format("YYYY-MM-DD"));
          current = current.subtract(1, "days");
        }
        this.filter.startDate = week[0];
        this.filter.endDate = week[6];
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      } else if (value === "lastmonth") {
        this.filterText = "Last Month";
        var month = new Array();
        let current = moment().subtract(1, "days");
        for (var j = 0; j < 30; j++) {
          month.push(current.format("YYYY-MM-DD"));
          current = current.subtract(1, "days");
        }
        this.filter.startDate = month[0];
        this.filter.endDate = month[29];
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      } else if (value === "Custom") {
        this.filterText = "Custom";
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      }
    },
    filterRows() {
      var input, filter, a, i, div, txtValue;
      filter = input.value.toUpperCase();
      div = document.getElementById("rowsDropdown");
      a = div.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    },
    callRangeModal() {
      this.$root.$emit("openCustomRangeModal");
    },
    datesGet() {
      this.filter.search = this.searched;
      const today = new Date();
      //today
      this.dateToday = moment(today, "YYYY/MM/DD").format("YYYY-MM-DD");
      //yesterday
      today.setDate(today.getDate() - 1);
      this.dateYesterday = moment(today, "YYYY/MM/DD").format("YYYY-MM-DD");
      //week
      var week = new Array();
      let current = moment().subtract(1, "days");
      for (var i = 0; i < 7; i++) {
        week.push(current.format("YYYY-MM-DD"));
        current = current.subtract(1, "days");
      }
      this.dateStartWeek = week[0];
      this.dateEndWeek = week[6];
      //month
      var month = new Array();
      for (var j = 0; j < 30; j++) {
        month.push(current.format("YYYY-MM-DD"));
        current = current.subtract(1, "days");
      }
      this.dateStartMonth = month[0];
      this.dateEndMonth = month[29];
    },

  },
  mounted() {
    this.datesGet();
    this.$root.$on("dataDateFromModal", (data) => {
      this.filter.startDate = data.start_date;
      this.filter.endDate = data.end_date;
      this.getFilters("Custom");
    });
  },
};
</script>
<style scoped>
.form-group {
  margin-bottom: 0.5em;
}
.date-pick {
  height: 45px;
  width: 100%;
  border: 1px solid var(--light-gray) !important;
  padding-top: auto;
  border-radius: 0.25rem;
  position: relative;
}
.date-pick input
  {
    width: 100%;
    border: none;
    padding: 5px;
}
.full-width-select {
  width: 100%;
}
.i {
  height: 20px;
  position: absolute;
  right: 0;
  padding-right: 3px;
}
.custom-select {
  border: 1px solid var(--light-gray) !important;
  border-radius: 0.25rem;
}

/* Custom Css for check inputs start */
/* .dis{
  display: contents!important;
} */
label {
  display: flex;
  align-items: center;
  /* color: #9e9e9e; */
  position: relative;
}

label > span {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  border: 2px solid #9e9e9e;
  margin-right: 10px;
  border-radius: 3px;
  transition: all 0.3s;
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input:checked + label > span {
  border: 7px solid #ffee00;
  animation: bounce 250ms;
}
input:checked + label > span::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 4px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checked-box 125ms 250ms forwards;
}
@keyframes checked-box {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate(0, 0) rotate(45deg);
  }
  33% {
    width: 4px;
    height: 0;
    border-color: #212121;
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    margin-top: 6px;
    width: 4px;
    height: 8px;
    border-color: #212121;
    transform: translate(0, -8px) rotate(45deg);
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.dropdown-menu-right span {
  font-weight: 500;
  color: var(--text-primary-color);
}


</style>